(function () {
  "use strict";

  let forms = document.querySelectorAll(".contact-us-form");

  forms.forEach(function (form) {
    form.addEventListener("submit", function (event) {
      event.preventDefault();
      grecaptcha.ready(function () {
        grecaptcha.execute("6LfBj1klAAAAAGIIDmH93JkjB_qaIbXrl6WTXX66", { action: "submit" }).then(function (token) {
          // Add your logic to submit to your backend server here.

          const data = new FormData(event.target);

          const name = data.get("name");
          const email = data.get("email");
          const message = data.get("message");
          var gcaptcha = data.get("g-captcha");
          var recaptcha = data.get("captcha");
          var generate = generateCaptcha();
          document.getElementById("generated-captcha").innerHTML = generate;
          document.getElementById("g-captcha").value = generate;
          document.getElementById("captcha").value = "";
          let action = form.getAttribute("action");
          if (!action) {
            displayError(form, "The form action property is not set!");
            return;
          }
          form.querySelector(".loading").classList.add("d-block");
          form.querySelector(".error-message").classList.remove("d-block");
          form.querySelector(".sent-message").classList.remove("d-block");

          if (recaptcha !== gcaptcha) {
            displayError(form, "The code was not valid. Please try agian.");
            return;
          }
          contact_us_form_submit(form, action, name, email, message, recaptcha);
        });
      });
    });
  });

  function contact_us_form_submit(thisForm, action, name, email, message, captcha) {
    const data = {
      name: name,
      email: email,
      message: message,
      captcha: captcha,
    };

    fetch(action, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log("Success:", data);
        thisForm.querySelector(".loading").classList.remove("d-block");
        if (data.response == "OK") {
          thisForm.querySelector(".sent-message").classList.add("d-block");
          thisForm.reset();
        } else if (data.response == "NOT OK") {
          displayError(thisForm, data.error);
        } else {
          displayError(thisForm, "Something went wrong. Please try agian later.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        thisForm.querySelector(".loading").classList.remove("d-block");
        displayError(thisForm, "Something went wrong. Please try agian later.");
      });
  }

  function displayError(thisForm, error) {
    thisForm.querySelector(".loading").classList.remove("d-block");
    thisForm.querySelector(".error-message").innerHTML = error;
    thisForm.querySelector(".error-message").classList.add("d-block");
  }
})();
